import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj
} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'

import GraphQLErrorList from '../../components/graphql-error-list'

import Container from '../../components/container'
import PortableText from '../../components/portableText'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'

import Greeting from '../../components/greeting'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AboutGreetingPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }

    greeting: sanityPages(
      slug: { current: {eq: "greeting"} }
    ) {
      title
      titleEn
      _rawBody(resolveReferences: {maxDepth: 5})
      mainImage{
        ...SanityImage
        alt
      }
    }
  }
`

const AboutGreetingPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const greeting = (data || {}).greeting

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title='学園長あいさつ'
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        {greeting && (
          <Greeting
            title={greeting.title}
            titleEn={greeting.titleEn}
            mainImage={greeting.mainImage}
            _rawBody={greeting._rawBody}
          />
        )}
      </Container>
    </Layout>
  )
}

export default AboutGreetingPage
